/* eslint-disable prettier/prettier */
export default {
  nav: {
    zh: '中文',
    en: 'English',
    ja: 'Nihongo',
    profile: 'Profile',
    payment: 'Payment',
    collections: 'Collections',
    voucher: 'Collections',
    openWallet: 'Open Wallet',
    logout: 'Log out',
  },
  sys: {
    notice: 'Notice',
    homeNotice: 'Buy NFT to unlock membership benefits, i.e. premium content, exclusive requests.',
    reserved: 'All rights reserved',
    copyright: 'Copyright',
    by: 'Powered by',
    terms: 'Terms',
    details: 'Details',
    pinned: 'Pinned',
    nothing: 'Nothing found',
    nothingDec: "We couldn't find anything with this criteria.",
    save: 'Save',
    cancel: 'Cancel',
    add: 'Add',
    pay: 'Pay',
    edit: 'Edit',
    connectWallet: 'Login/Connect Wallet',
    logIn: 'Log in',
    viewCollection: 'View Collection',
    Finished: 'Finished',
    publicSale: 'Public Sale',
    itemsMinted: 'Items minted',
    itemCollected: 'Items collected',
    Collect: 'Collect',
    totalRaised: 'Total raised',
    liveNow: 'LIVE NOW',
    mintSoldOut: 'MINT SOLD OUT',
    wereMinted: 'All items were minted.',
    signing: 'Sign In...',
    signInDec: 'Please sign your signature in your wallet to continue login',
    signIn: 'Sign In',
    switchAccount: 'Switch Account',
    connectToYour: 'Connect to your ',
    wallet: ' Wallet',
    account: ' account',
    connectWithYour: 'Connect with your ',
    scanWith: 'Scan with ',
    toConnect: ' to connect',
    logInWith: 'Log in with',
    nothingAbout1: 'Hi, welcome to our global metaverse club.',
    nothingAbout2: 'We will update more details about the club soonest.',
    successfull: 'successfull',
    copySuccess: 'copy success',
    NoCollectorYet: 'No collector yet.',
    payNow: 'Pay Now',
    BUY: 'BUY',
    Buy: 'Buy',
  },
  loginMsg: {
    msg1: 'Your wallet is connected to the unexpected network, please switch to network to Goerli',
    msg2: 'Your wallet is connected to the unexpected network, please switch to network to Mainnet',
    msg3: 'Your wallet will be generated logging in.',
  },
  user: {
    editProfile: 'Edit Profile',
    profileImage: 'Profile image',
    displayName: 'Display name',
    paymentMethod: 'Payment Method',
    paymentMethodDec: 'Enable and manage your payment methods.',
    noCard: "You haven't added any card yet.",
    creditCard: 'credit card',
    carNumber: 'Card Number',
    globalMetaverseClub: 'Global Metaverse Club',
    dec: "That's a very mysterious profile",
    showMore: 'Show more',
    meetMe: 'Chat with me',
    contactUs: 'Contact us',
    showLess: 'Show less',
    DownLoad: 'DownLoad',
    Share: 'Share',
  },
  nft: {
    statistic: {
      nfts: 'Items',
      owners: 'Collected',
      fans: 'Fans',
      total: 'Volume',
      treasure: 'Total treasure',
    },
    address: 'Contract Address',
    tokenId: 'Token ID',
    blockchain: 'Blockchain',
    DateCollected: 'Date Collected',
    owner: 'Owner',
    recentActivity: 'Recent Activity',
    noRecent: 'No recent activity',
    table: {
      Event: 'Event',
      Price: 'Price',
      User: 'User',
      Free: 'Free',
    },
    buyNow: 'Buy Now',
    freeMint: 'Free Mint',
    processingMint: 'Processing Mint...',
    msg: {
      noMoney:
        "You don't have enough funds to complete the purchase. Please deposit or convert your funds.",
      wrong: 'Something wrong. Please try again later.',
      boughtByElse: 'Bought by someone else',
      buySuccess:
        'Your NFT has been purchased successfully! Please enjoy the premium member content!',
      genesis: `Buy NFT to become <b>a club member</b> and <b>unlock member benefits</b>, e.g. premium
      group, exclusive content, NFT airdrop and more.`,
      Delivery: 'Delivery time is longer than expected, please be patient.',
    },
    viewOpenSea: 'Buy on OpenSea',
    initialSale: 'initial sale',
    tokenStandard: 'Token Standard',
    buttonText: {
      LockedByAnotherUser: 'Locked by another user',
      DeliveringNFT: 'Delivering NFT',
      YourOwned: 'Your Owned',
      ProcessingPayment: 'Processing Payment',
    },
    buyWithUSD: 'No ETH? Contact us to get ETH',
    searchPlaceholder: 'Enter a token id to select an item',
    nftEmpty: {
      text: 'Nothing found',
      subText: "We couldn't find anything with this criteria",
      comsoon: 'coming soon',
    },
    featuredNFTs: 'Featured NFTs',
    viewAll: 'View all',
    viewAllNFTs: 'View all NFTs',
    featuredContracts: 'Featured Collections',
    digitalCollectiables: 'Digital Collectibles',
    nftInfo: {
      items: 'Items',
      collectors: 'Collectors',
      volume: 'Volume',
      treasure: 'Total treasure',
      address: 'Address',
      treasureFunds: 'Treasure Funds',
      withdrawableAmount: 'Next Dividends Amount',
      nextWithdrawalDate: 'Next Dividends Date',
      withdraw: 'Withdraw Earnings',
      collected: 'Collected',
    },
    nftTips: 'Please wait for a while until the completion of minting.',
    noSale: 'This NFT does not start the initial sale yet.',
  },
  collectorsView: {
    owned: 'Owned NFTs',
    collector: 'Collector',
    volume: 'Volume',
  },
  posts: {
    joinGroup: 'Only the member can join the chat group.',
    BecomeNFTMember: 'Become NFT member',
    ItemsMinted: 'Items minted',
    StartFrom: 'Start from',
    OtherMembershipCards: 'Other membership cards',
    SelectmembershipCard: 'Select a membership card',
    About: 'About',
    Club: 'Ranking',
    Collectors: 'Collectors',
    Posts: 'Posts',
    buy: 'Join now',
    dec: 'Unlock this post<br/> by becoming a member',
    dec1: 'Enjoy this Post? Support',
    viewMore: 'View more comments',
    placeholder: 'Write a comment...',
    post: 'Post',
    Play: 'Play',
    AddToPlaylist: 'Add to playlist',
    msg: {
      msg1: '<b>NFT members</b> can access exclusive content, receive club event notifications, and interact with other members.',
      comingSoon: 'Coming soon...',
    },
  },
  benefits: {
    benefits: 'Benefits',
    tit1: 'Limited Edition Ownership',
    dec1: 'Own the collectible and show it on your collection!',
    tit2: 'NFT Chat',
    dec2: 'The creator can create a chat for this NFT.',
    tit3: 'Exclusive Collector’s Club',
    dec3: 'Get access to Collector’s Club.',
    tit4: 'Premium Access to DMs',
    dec4: 'Get premium direct message access.',
    tit5: 'Get Carbon Neutral Certificate',
    dec5: 'Certificate No.{tips}',
  },
  intro: {
    username:
      'Welcome to the metaverse club! You can follow the tutorial and explore more about the club.',
    NFT: 'You can browser all NFTs on sale. Purchase an NFT to be the metaverse club member and enjoys all exclusive club member benefits. ',
    posts:
      'NFT members can access exclusive club content, receive club activity notifications, and interact with other members.',
    club: 'NFT members can join club member group, chat with other members, and join club chat room.',
    loginBtn: 'You can enter the club through your wallet or other social media accounts.',
    wallet:
      "You can enter the club with your crypto wallet. Please click the wallet icon to connect your wallet.  If you don't have any crypto wallet yet, click 'Next' to use your social media account to enter the club.",
    contact: 'Use your social media account to enter the club.',
    next: 'Next',
    skip: 'Skip',
    ok: 'Ok',
  },
  collections: {
    genesisNFTS: 'Genesis NFTs',
    collectables: 'Collectables',
    viewEarnings: 'View Earnings',
    collections: 'Collections',
  },
  earnings: {
    title: 'Genesis NFT Earnings',
    amount: 'Available withdrawal amount',
    withdraw: 'Withdraw Now',
    expectedAmount: 'Next Dividends Amount',
    nextDate: 'Next Dividends Date',
    history: 'Payment History',
    send: 'Withdraw Success',
    sendFail: 'Withdrawal failed. Please try again.',
    dividend: 'Dividend',
    withdrawal: 'Withdrawal',
  },
  deepLinkBenefits: {
    dec2: 'DeepLink Token Airdrop',
    dec3: 'DeepLink Incubated Gaming Project Token Airdrop',
    dec4: 'Rights to vote for deciding which games to launch',
    dec5: 'Discount on Game NFT Purchase ',
    dec6: 'Cloud game copyright and discount on game time purchase ',
    dec7: 'Other rights to participate in community governance',
  },
  topup: {
    title: 'Please deposit or convert your funds',
    subTitle: 'You don’t have enough funds to complete the purchase.',
    amount: ' Recommended top-up amount:',
    crypto: 'Deposit Crypto',
    buyCard: 'Buy With Card',
    tip: 'Transfer funds from an exchange or another wallet to your wallet address below:',
    walletTitle: 'Buy ETH with Particle Network',
    walletContent:
      'Particle Network supports popular payment methods, including Visa, Mastercard and bank transfers in many countries,. Tokens deposit into your wallet.',
    connect: 'Continue to Particle Network',
  },
  copy: 'Copy',
  date: 'Date',
  token: 'Token',
  total: 'Total',
  memo: 'Memo',
  transactionId: 'Transaction ID',
  notes: 'Notes',
  slashwhite: {
    item: 'item',
    LimitOne: '1 per wallet',
    ItemsMinted: 'Items minted',
    liveNow: 'LIVE NOW',
    viewOn: 'view on',
    Time: 'Time',
    Limit: 'Limit',

    mintOut: 'MINT SOLD OUT',
    publicSale: 'Public Sale',
    LimitNumber: 'Limit {presaleMaxWallet} per wallet',
    MintSchedule: 'Mint schedule',
    Mint: 'Mint',
    NotEligible: 'Not Eligible',
    Eligible: 'Eligible',
    upComing: 'UPCOMING',
    mintingNow: 'MINTING NOW',
    startsIn: 'Starts in',
    endsIn: 'Ends in',
    items: 'items',
    connect: 'Connect Wallet',
    collectedSuccessfully: 'Collected successfully',
    dfansTips1:
      'Your Creator NFT is minted successfully. Please close this window and go back to the registration page to verify your Creator NFT again.',
    dfansTips2: 'Once NFT is verified, you can continue the registration procedure.',
    close: 'close',

    collector: 'COLLECTOR',
    address: 'CONTRACT ADDRESS',
    blockchain: 'BLOCKCHAIN',
    DateCollected: 'DATE COLLECTED',
    soldOut: 'sold out!',
    Collect: 'Collect',
    Collected: 'Collected',
    NotStart: ' Not Start',
  },
  searc: 'search',
}

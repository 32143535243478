import mitt from 'mitt'
import router from '@/router'
const eventBus = mitt()
export default eventBus

export enum BUS_EVENTS {
  EVENT_BUS_REFRESH = 'EVENT_BUS_REFRESH',
}

export const REFRESH = (cb?: Function) => {
  eventBus.emit(BUS_EVENTS.EVENT_BUS_REFRESH, () => {
    router.push({ name: 'Home' })
    setTimeout(() => {
      cb?.()
    })
  })
}

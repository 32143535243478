export default {
  nav: {
    zh: '中文',
    en: 'English',
    ja: 'Nihongo',
    profile: '个人信息',
    payment: '支付信息',
    collections: '我的藏品',
    voucher: '我的助力凭证',
    openWallet: '打开钱包',
    logout: '退出登录',
  },
  sys: {
    notice: '通知',
    homeNotice: '购买 NFT 以解锁会员福利，如会员专属内容。',
    reserved: '保留所有权利',
    copyright: '版权',
    by: '技术支持',
    terms: '条款',
    details: '详情',
    pinned: '置顶',
    nothing: '暂无数据',
    nothingDec: '找不到符合的任何内容。',
    save: '保存',
    cancel: '取消',
    add: '添加',
    pay: '请支付',
    edit: '编辑',
    connectWallet: '登录/连接钱包',
    logIn: '登录',
    viewCollection: '查看合约',
    Finished: '已结束',
    publicSale: '公开销售',
    itemsMinted: '铸造的项目',
    itemCollected: '已领取',
    Collect: '领取',
    totalRaised: '募集总额',
    liveNow: 'LIVE NOW',
    mintSoldOut: 'MINT SOLD OUT',
    wereMinted: '所有项目均已铸造。',
    signing: '登录中...',
    signInDec: '请在钱包中签名以继续登录',
    signIn: '登录',
    switchAccount: '切换账户',
    connectToYour: '连接到你的',
    wallet: '钱包',
    account: '账户',
    connectWithYour: '连接你的',
    scanWith: '扫描',
    toConnect: '连接',
    logInWith: '其他登录',
    nothingAbout1: '嗨，欢迎来到我们的全球元宇宙俱乐部。',
    nothingAbout2: '我们将尽快更新俱乐部的更多信息。',
    successfull: '成功',
    copySuccess: '复制成功',
    NoCollectorYet: '暂无收藏',
    payNow: '立即支付',
    BUY: '购买',
    Buy: '购买',
  },
  loginMsg: {
    msg1: '您的钱包已连接到其他网络，请切换到网络到Goerli',
    msg2: '您的钱包已连接到其他网络，请切换到网络到Mainnet',
    msg3: '您的钱包将在登录后自动创建。',
  },
  user: {
    editProfile: '编辑信息',
    profileImage: '用户头像',
    displayName: '用户名称',
    paymentMethod: '支付方式',
    paymentMethodDec: '管理您的付款方式。',
    noCard: '您尚未添加任何卡片。',
    creditCard: '信用卡',
    carNumber: '信用卡号',
    globalMetaverseClub: '全球元宇宙俱乐部',
    dec: '这是一个神秘人的个人资料',
    showMore: '展开',
    meetMe: '聊天',
    contactUs: '联系我们',
    showLess: '收起',
    DownLoad: '下载',
    Share: '分享',
  },
  nft: {
    statistic: {
      nfts: 'NFT 数量',
      owners: 'NFT 售出数量',
      fans: '粉丝数',
      total: 'NFT 成交量',
      treasure: '分红',
    },
    address: '钱包地址',
    tokenId: '令牌 ID',
    blockchain: '区块链',
    DateCollected: '收藏日期',
    owner: '所有者',
    recentActivity: '近期活动',
    noRecent: '暂无信息',
    table: {
      Event: '事件',
      Price: '价格',
      User: '用户',
      Free: '免费',
    },
    buyNow: '立即购买',
    freeMint: '免费铸造',
    processingMint: '正在发布...',
    msg: {
      noMoney: '您没有足够的资金来完成购买。请存入或转换您的资金。',
      wrong: '出错了。请稍后重试。',
      boughtByElse: '已被别人买购买',
      buySuccess: '您的 NFT 已成功购买！请享受专属高级会员内容！',
      genesis:
        '购买NFT成为<b>俱乐部会员</b>和<b>解锁会员福利</b>，例如溢价群组、独家内容、NFT空投等。',
      Delivery: '交货时间超出预期，请耐心等待。',
    },
    viewOpenSea: 'OpenSea购买',
    initialSale: '首次售卖',
    tokenStandard: '令牌协议',
    buttonText: {
      LockedByAnotherUser: '被其他用户锁定',
      DeliveringNFT: '交付NFT',
      YourOwned: '你拥有的',
      ProcessingPayment: '处理付款',
    },
    buyWithUSD: '没有ETH？联系我们获取ETH',
    searchPlaceholder: '输入一个token id快速跳转',
    nftEmpty: {
      text: '暂无数据',
      subText: '我们找不到符合此条件的任何内容',
      comsoon: 'coming soon',
    },
    featuredNFTs: '精选NFT',
    viewAll: '查看全部',
    viewAllNFTs: '查看全部',
    featuredContracts: '精选合约',
    digitalCollectiables: '数字藏品',
    nftInfo: {
      items: '总量',
      collectors: '持有者人数',
      volume: '成交金额',
      treasure: '累积分红金额',
      treasureFunds: '分红池金额',
      address: '地址',
      withdrawableAmount: '下一次分红金额',
      nextWithdrawalDate: '下次分红日期',
      withdraw: '提取收益',
      collected: '售卖数量',
    },
    nftTips: '请稍等片刻，直到铸造完成',
    noSale: '该 NFT 尚未开始初始销售。',
  },
  collectorsView: {
    owned: '拥有 NFT 数量',
    collector: '收藏者',
    volume: '成交量',
  },
  posts: {
    joinGroup: '只有该成员可以加入聊天组。',
    BecomeNFTMember: '成为NFT会员',
    ItemsMinted: '总数',
    StartFrom: '开始于',
    OtherMembershipCards: '其他会员卡',
    SelectmembershipCard: '选择会员卡',
    About: '关于',
    Club: '排行榜',
    Collectors: '领取者',
    Posts: '文章',
    buy: '立即加入',
    dec: '加入会员解锁',
    dec1: '喜欢这个帖子吗？支持',
    viewMore: '查看更多评论',
    placeholder: '写评论...',
    post: '提交',
    Play: '播放',
    AddToPlaylist: '添加到播放列表',
    msg: {
      msg1: '<b>NFT会员</b>可以访问独家内容，接收俱乐部活动通知，并与其他会员互动。',
      comingSoon: '即将开放...',
    },
  },
  benefits: {
    benefits: '权益',
    tit1: '限量版所有权',
    dec1: '拥有收藏品，并在您的收藏中展示！',
    tit2: 'NFT 聊天',
    dec2: '创作者可以建立 NFT 聊天群组。',
    tit3: '独家收藏家俱乐部',
    dec3: '访问收藏家俱乐部。',
    tit4: '专属直接聊天权限',
    dec4: '获取专属直接消息访问权限。',
    tit5: '获得碳排放证书',
    dec5: '证书编号:LTY-2023-{tips}',
  },
  intro: {
    username: '欢迎来到元宇宙俱乐部的！你可以跟随教程进一步了解这里。',
    NFT: '您可以浏览所有出售的NFT。购买NFT成为元宇宙俱乐部会员，并享受所有独家俱乐部会员福利。',
    posts: 'NFT会员可以访问独家俱乐部内容，接收俱乐部活动通知，并与其他会员互动。',
    club: 'NFT会员可以加入俱乐部会员组，与其他会员聊天，并加入俱乐部聊天室。',
    loginBtn: '您可以通过钱包或其他社交媒体帐户进入俱乐部。',
    wallet:
      '你可以用你的加密钱包进入俱乐部。请单击钱包图标以连接您的钱包。如果您还没有任何加密钱包，请单击“下一步”使用您的社交帐户进入俱乐部。',
    contact: '点击社交账号进行验证并进入俱乐部。',
    next: '下一步',
    skip: '跳过',
    ok: '完成',
  },
  collections: {
    genesisNFTS: '创世合约',
    collectables: '收藏品',
    viewEarnings: '查看收入',
    collections: '收藏品',
  },
  earnings: {
    title: '创世NFT收益',
    amount: '可用提款金额',
    withdraw: '立即提取',
    expectedAmount: '下一次分红金额',
    nextDate: '下次分红日期',
    history: '支付历史',
    send: '提现成功',
    sendFail: '提现失败，请重试',
    dividend: '分红',
    withdrawal: '提现',
  },
  deepLinkBenefits: {
    dec2: 'DeepLink令牌空投',
    dec3: 'DeepLink孵化游戏项目令牌空投',
    dec4: '决定推出哪些游戏的投票权',
    dec5: '购买游戏NFT的折扣',
    dec6: '云游戏版权和游戏时间购买折扣',
    dec7: '参与社区治理的其他权利',
  },
  topup: {
    title: '请存入或转换您的资金',
    subTitle: '您没有足够的资金来完成购买。',
    amount: ' 建议的充值金额:',
    crypto: '存款密码',
    buyCard: '用卡购买',
    tip: '将资金从交易所或其他钱包转移到您的以下钱包地址:',
    walletTitle: '通过Particle Network购买ETH',
    walletContent:
      'Particle Network 支持流行的支付方式，包括许多国家的Visa、Mastercard和银行转帐。代币存入你的钱包。',
    connect: '继续连接Particle Network',
  },
  copy: '复制',
  date: '日期',
  token: '令牌',
  total: '总数',
  memo: '备注',
  transactionId: '交易ID',
  notes: '备注',
  slashwhite: {
    item: '份',
    LimitOne: '每位用户限领一份',
    ItemsMinted: '已领取数量',
    liveNow: '领取中',
    viewOn: '查看',
    Time: '时间',
    Limit: '限制',

    mintOut: '售罄',
    publicSale: '公售',
    LimitNumber: '每位用户限领{presaleMaxWallet}份',
    MintSchedule: '铸造时间表',
    Mint: '铸造',
    NotEligible: '不符合资格',
    Eligible: '有资格',
    upComing: '即将到来',
    mintingNow: '铸造中',
    startsIn: '开始于',
    endsIn: '结束于',
    items: '份',
    connect: '连接钱包',
    collectedSuccessfully: '创作成功',
    dfansTips1: '您的创建者NFT已成功铸造。请关闭此窗口并返回注册页面，再次验证您的创建者NFT。',
    dfansTips2: '一旦验证了NFT，您就可以继续注册程序。',
    close: '关闭页面',

    collector: '收藏者',
    address: '钱包地址',
    blockchain: '区块链',
    DateCollected: '收藏日期',
    soldOut: '已售空!',
    ActiveTime: '活动时间',
    AmountRaised: '已筹金额',
    Targetmoney: '目标金额',
    AssistanceFund: '还需助力金',
    Collect: '领取',
    Collected: '已领取',
    NotStart: ' 未开始',
  },
  search: '搜索',
}

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import pinia from './stores'
import './style/index.scss'
import 'windi.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import 'element-plus/es/components/message-box/style/index'
import 'element-plus/es/components/message/style/index'
import 'element-plus/es/components/notification/style/index'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { checkUserLoginDate } from '@/utils/auth'
import i18n from './lang'
import './utils/useLogin'

checkUserLoginDate()
const app = createApp(App)
app.use(router).use(pinia).use(i18n).use(VueVirtualScroller).mount('#app')
